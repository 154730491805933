import { render, staticRenderFns } from "./ProfileSelector.vue?vue&type=template&id=476bb10f&scoped=true&"
import script from "./ProfileSelector.vue?vue&type=script&lang=js&"
export * from "./ProfileSelector.vue?vue&type=script&lang=js&"
import style0 from "./ProfileSelector.vue?vue&type=style&index=0&id=476bb10f&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "476bb10f",
  null
  
)


    import installComponents from "!/builds/rentmagic/rentmagic.website.vue.vlaams-brabant/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BAlert, BButton, BCard, BCol, BRow, BContainer} from 'bootstrap-vue'
    installComponents(component, {BAlert, BButton, BCard, BCol, BRow, BContainer})
    

export default component.exports